<template>
  <v-select
    :value="current"
    :items="Items"
    :item-text="'Name'"
    :item-value="'Field'"
    :label="label"
    @input="onChanged"
  />
</template>
<script>
import { SegmentFilterTypes } from "@/models/segment-filter-types";

export default {
  props: ["value"],
  watch: {
    value() {
      this.current = this.value;
    },
  },
  data() {
    return {
      label: "Campo",
      current: this.value,
      Items: SegmentFilterTypes,
    };
  },
  methods: {
    onChanged(e) {
      this.$emit("input", e);
    },
  },
};
</script>
