export const Equals = {
  TypeId: "eq",
  Name: "=",
};
export const GreaterThan = {
  TypeId: "gt",
  Name: ">",
};
export const GreaterOrEqualThan = {
  TypeId: "gte",
  Name: ">=",
};
export const LowerThan = {
  TypeId: "lt",
  Name: "<",
};
export const LowerOrEqualThan = {
  TypeId: "lte",
  Name: "<=",
};
export const FilterOperatorTypes = [
  Equals,
  GreaterThan,
  GreaterOrEqualThan,
  LowerThan,
  LowerOrEqualThan,
];

export function GetTypeNameById(id) {
  for (let index = 0; index < FilterOperatorTypes.length; index++) {
    if (FilterOperatorTypes[index].TypeId === id) {
      return FilterOperatorTypes[index].Name;
    }
  }
  return "";
}
