<template>
  <div>
    <div class="k-grid k-grid-toolbar">
      <app-form-modal
        titleButton="Adicionar Filtro"
        class-icon="icon-add"
        :title-dialog="`${isNew ? 'Adicionar' : 'Editar'} Filtro`"
        :confirmAction="saveInstance"
        :cancelAction="clearData"
        :formValid="!$v.Instance.$error"
        ref="modal"
      >
        <field-selector-component
          v-model="Instance.Field"
        ></field-selector-component>

        <filter-selector-component
          v-model="Instance.Operation"
        ></filter-selector-component>

        <component :is="component" v-if="component" v-model="Instance.Value" />
      </app-form-modal>
    </div>
    <Grid
      :style="{ height: 'auto' }"
      :data-items="Items"
      :columns="Columns"
      @edit="edit"
      @remove="remove"
    >
      <template slot="TypeCell" slot-scope="{ props }">
        <td :class="props.className">
          {{ getQuestionTypeName(props.dataItem) }}
        </td>
      </template>
      <GridNoRecords> Não existem filtros </GridNoRecords>
    </Grid>
  </div>
</template>

<script>
import * as kendo from "@progress/kendo-ui";
import { required } from "vuelidate/lib/validators";
import { notificationServiceBus } from "@/services/bus/notificationServiceBus";
import workflowSegmentFiltersService from "@/services/api/workflowSegmentFiltersService";
import FormModalComponent from "@/components/modals/form-modal";
import FilterOperatorsSelector from "@/components/selectors/filter-operators";
import FieldSelector from "@/components/selectors/segment-filter-types";
import JobLocationSelectorComponent from "@/components/selectors/job-locations";
import JobFunctionSelectorComponent from "@/components/selectors/job-functions";
import CoursesSelectorComponent from "@/components/selectors/courses";
import JobCostCenterSelectorComponent from "@/components/selectors/job-costcenters";
import EditCommandCell from "@/components/grid/edit-command.js";
import DatePickerComponent from "@/components/forms/date-picker";
import DeleteCommandCell from "@/components/grid/delete-command.js";
import NumberInputComponent from "@/components/selectors/number-input";
import { GetSegmentFilterTypesTypeTypeByField } from "@/models/segment-filter-types";

export default {
  components: {
    "app-form-modal": FormModalComponent,
    "filter-selector-component": FilterOperatorsSelector,
    "field-selector-component": FieldSelector,
    "number-component": NumberInputComponent,
    "date-component": DatePickerComponent,
    "job-locations-component": JobLocationSelectorComponent,
    "job-functions-component": JobFunctionSelectorComponent,
    "job-costcenters-component": JobCostCenterSelectorComponent,
    "courses-component": CoursesSelectorComponent,
  },
  props: ["dataItem"],
  data() {
    return {
      Instance: {
        IdSurveyQuestion: 0,
        OrderId: 1,
        Title: "",
        IdSegment: this.dataItem.IdWorkflowSegment,
      },
      Items: [],
      Columns: [
        { field: "Field", title: "Campo" },
        { field: "Operation", title: "Operação" },
        { field: "Value", title: "Valor" },
        {
          cell: EditCommandCell,
          filterable: false,
          sortable: false,
          width: 75,
          title: "",
        },
        {
          cell: DeleteCommandCell,
          filterable: false,
          sortable: false,
          width: 75,
          title: "",
        },
      ],
    };
  },
  validations: {
    Instance: {
      Field: {
        required,
      },
      Value: {
        required,
      },
      Operation: {
        required,
      },
    },
  },
  computed: {
    component() {
      const type = GetSegmentFilterTypesTypeTypeByField(this.Instance.Field);
      if (!type) {
        return null;
      }
      return type + "-component";
    },
    isNew() {
      return (
        this.Instance != null && this.Instance.IdWorkflowSegmentFilter == 0
      );
    },
  },
  methods: {
    onOperationSelected(id) {
      this.Instance.Operation = id;
    },
    openModal() {
      this.$refs.modal.open();
    },
    clearData(callBack) {
      this.resetInstance();
      callBack(true);
    },
    edit(item) {
      this.Instance = { ...item.dataItem };
      this.openModal();
    },
    remove(item) {
      this.deleteInstance(item.dataItem);
    },
    resetInstance() {
      this.Instance = {
        IdWorkflowSegmentFilter: 0,
        Value: "",
        Operation: "",
        Field: "",
        IdSegment:
          this.dataItem === null ? null : this.dataItem.IdWorkflowSegment,
      };
    },
    async loadItems() {
      let options = {
        Filters: [],
        Order: [],
      };
      if (this.dataItem) {
        options.Filters.push({
          Field: "IdSegment",
          Operation: "eq",
          Value: this.dataItem.IdWorkflowSegment,
        });
      } else {
        options.Filters.push({
          Field: "IdSegment",
          Operation: "eq",
          Value: null,
        });
      }
      await workflowSegmentFiltersService
        .Get(options)
        .then((res) => {
          this.Items = res.data.Items;
          this.resetInstance();
        })
        .catch((error) => {
          notificationServiceBus.showError(error.response);
        });
    },
    async saveInstance(callBack) {
      if (this.isNew) {
        await workflowSegmentFiltersService
          .Create(this.Instance)
          .then((response) => {
            notificationServiceBus.showSuccessMessage(
              "Successo!",
              "Criou o filtro com sucesso!"
            );
            this.Items.push(response.data.Instance);
            this.resetInstance();
            callBack(true);
          })
          .catch((error) => {
            callBack(false);
            notificationServiceBus.showError(error.response);
          });
      } else {
        await workflowSegmentFiltersService
          .Update(this.Instance)
          .then((response) => {
            notificationServiceBus.showSuccessMessage(
              "Successo!",
              "Editou o filtro com sucesso!"
            );
            const index = this.Items.findIndex(
              (s) =>
                s.IdWorkflowSegmentFilter ==
                this.Instance.IdWorkflowSegmentFilter
            );
            if (index >= 0) {
              this.Items[index] = response.data.Instance;
            }
            this.resetInstance();
            callBack(true);
          })
          .catch((error) => {
            callBack(false);
            notificationServiceBus.showError(error.response);
          });
      }
    },
    deleteInstance(item) {
      var id = item.IdWorkflowSegmentFilter;
      kendo.confirm("Tem a certeza que quer apagar o filtro?").done(() => {
        workflowSegmentFiltersService
          .Delete(id)
          .then(() => {
            notificationServiceBus.showSuccessMessage(
              "Successo!",
              "Apagou o filtro com sucesso!"
            );
            this.Items = this.Items.filter(
              (s) => s.IdWorkflowSegmentFilter !== id
            );
            this.resetInstance();
          })
          .catch((error) => {
            notificationServiceBus.showError(error.response);
          });
      });
    },
  },
  async created() {
    this.resetInstance();
    this.$v.$touch();
    await this.loadItems();
  },
  mounted() {},
};
</script>

<style></style>
