<template>
  <v-select
    v-model="current"
    :items="Items"
    :item-text="'Name'"
    :item-value="'TypeId'"
    :label="label"
    @input="onChanged"
  />
</template>
<script>
import { FilterOperatorTypes } from "@/models/filter-operators-types";

export default {
  props: ["value"],
  watch: {
    value() {
      this.current = this.value;
    },
  },
  data() {
    return {
      label: "Filtro",
      current: this.value,
      Items: FilterOperatorTypes,
    };
  },
  methods: {
    onChanged(e) {
      this.$emit("input", e);
    },
  },
};
</script>
